<div class="form-group">
    <div class="radio-button">
        <div class="general-offer-row">
            <label class="custom-radio-container any">
                <input
                    name="anyDriver"
                    type="radio"
                    (ngModelChange)="driverTypeClick($event)"
                    [value]="OfferType.Any"
                    [disabled]="isDisabledDriverSection()"
                    [(ngModel)]="offerType">
                <span [translate]="'tuya-shipment.general-offer.radio.any-driver'"></span>
                <span class="checkmark"
                        tooltip="{{'tuya-shipment.unable-change' | translate}}"
                        placement="top"
                        container="body"
                        containerClass="tuya-tooltip"
                        [isDisabled]="getDisabledAnyStatement()"></span>
            </label>
        </div>
        <span class="divider"></span>
        <div class="general-offer-row">
            <label class="custom-radio-container limited">
                <input
                    name="limitedOffer"
                    type="radio"
                    (ngModelChange)="driverTypeClick($event)"
                    [value]="OfferType.DriversPool"
                    [disabled]="isDisabledDriverSection()"
                    (click)="driverTypeClick(OfferType.DriversPool)"
                    [(ngModel)]="offerType">
                <span [translate]="'tuya-shipment.general-offer.radio.limited'"></span>
                <span class="checkmark"
                        tooltip="{{'tuya-shipment.unable-change' | translate}}"
                        placement="top"
                        container="body"
                        [isDisabled]="getDisabledExclusiveStatement()"
                        containerClass="tuya-tooltip" ></span>
            </label>

            <div class="general-offer-select-container">
                <tuya-ng-select #driverPoolsSelect
                        [multiple]="true"
                        [items]="pools"
                        [initialField]="'drivers'"
                        [disabled]="disabledLimited || isDisabledDriverSection()"
                        [hasAddButton]="false"
                        [hasSubmitButton]="false"
                        (submitSelected)="submitSelectedPools($event)"
                        (data)="submitSelectedPools($event)"
                        [staticItem]="'true'"
                        staticItemLabel="{{'tuya-shipment.general-offer.no-matches' | translate}}"
                        placeholder="{{'tuya-shipment.general-offer.select-driver' | translate}}">
                </tuya-ng-select>
            </div>
        </div>
        <div class="general-offer-row">
            <label class="custom-radio-container exclusive">
                <input
                    name="exclusiveOffer"
                    type="radio"
                    (ngModelChange)="driverTypeClick($event)"
                    [value]="OfferType.Exclusive"
                    [disabled]="isDisabledDriverSection()"
                    (click)="driverTypeClick(OfferType.Exclusive)"
                    [(ngModel)]="offerType">
                <span [translate]="'tuya-shipment.general-offer.radio.exclusive'"></span>
                <span class="checkmark"
                        tooltip="{{'tuya-shipment.unable-change' | translate}}"
                        placement="top"
                        container="body"
                        [isDisabled]="getDisabledExclusiveStatement()"
                        containerClass="tuya-tooltip" ></span>
            </label>
            <div class="general-offer-select-container">
                <tuya-ng-select #exclusiveDriversSelect
                        [multiple]="true"
                        [textField]="'fullName'"
                        [idField]="'userAccountId'"
                        [hasAddButton]="false"
                        [hasSubmitButton]="false"
                        [disabled]="disabledExclusive || isDisabledDriverSection()"
                        [pending]="isDriversLoading"
                        (submitSelected)="submitSelectedDrivers($event)"
                        (data)="submitSelectedDrivers($event)"
                        (typed)="onTypedEventHandler($event)"
                        [staticItem]="'true'"
                        staticItemLabel="{{'tuya-shipment.general-offer.no-matches' | translate}}"
                        placeholder="{{'tuya-shipment.general-offer.enter-driver' | translate}}">
                </tuya-ng-select>
            </div>
            <div class="re-offer-container">
                <div class="form-checkbox">
                    <label for="re-offer-order" class="control control-checkbox">
                        <input type="checkbox" [(ngModel)]="isExtendable" id="re-offer-order" [disabled]="disabledIsExtendable" (change)="changeExtendable()" />
                        <span [translate]="'tuya-shipment.general-offer.re-offer'"></span>
                        <div class="control_indicator"></div>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <tuya-spinner *ngIf="isLoading$ | async" size="fullcontained"></tuya-spinner>
</div>