import { Component, OnInit, OnChanges, EventEmitter, Input, Output, ChangeDetectorRef, SimpleChanges, ViewChild } from '@angular/core';
import { ISearchResult } from './search-result.model';
import { some } from 'lodash-es';

@Component({
  selector: 'tuya-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss']
})
export class SearchSelectComponent implements OnInit, OnChanges {

  /**
   * Reference to text input in template.
   */
  @ViewChild('textInput', {static: true}) textInput;

  /**
   * Emit event in typing in text input.
   */
  @Output() typed: EventEmitter<string> = new EventEmitter();

  /**
   * Emit event when focusing in on text input.
   */
  @Output() textFocus: EventEmitter<any> = new EventEmitter();

  /**
   * Emit event when focus leaves text input.
   */
  @Output() textFocusOut: EventEmitter<any> = new EventEmitter();

  /**
   * Emit event when search results update.
   */
  @Output() changeSearchResults: EventEmitter<any> = new EventEmitter();

  /**
   * Emit event when items are selected.
   */
  @Output() onSelectItems: EventEmitter<any> = new EventEmitter();

  /**
   * Component-level storage for search results.
   */
  public _searchResults = [];

  /**
   * Component-level storage for selected items.
   */
  public _selectedItems = [];

  /**
   * Display search results toggle.
   */
  public shelfOpen = false;

  /**
   * Text entered into text input.
   */
  public enteredText = '';

  /**
   * Adds class to text input to indicate loading.
   */
  @Input() pending: boolean;

  /**
   * Disables text input.
   */
  @Input() disabled: boolean;

  /**
   * Input selected items as a prop.
   */
  @Input() selections: Array<any>;

  /**
   * Placeholder text in text input.
   */
  @Input() placeholder: string;

  /**
   * Define whether field is a multi or single select.
   * False to restrict to single values.
   */
  @Input() multiSelect: boolean;

  /**
   * Search results selected in shelf. Local variable
   */
  public selectedSearchResults = [];

  /**
   * Input search results as a prop.
   */
  @Input()
  set searchResults(searchResults: ISearchResult[]) {
    this._searchResults = searchResults;
    this.shelfOpen = true;
    if (this.enteredText && this.enteredText.length > 0) {
      if (this._searchResults.length === 0 && !this.pending) {
        this.displayNoMatches = true;
      } else {
        this.displayNoMatches = false;
      }
      // Focus out text input, otherwise it takes two clicks to select a search result.
      // Not needed if results are front-end filtered
      if (this.autoFocusOut && this._searchResults.length > 0) {
        this.textInput.nativeElement.blur();
      }
    }
  }

  @Input() offerType: number;
  @Input() autoFocusOut = false;
  public displayNoMatches = false;

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
  }

  /**
   * Set internal arrays to match input props.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selections) {
      this._selectedItems = changes.selections.currentValue || [];
    }
    if (changes.searchResults) {
      this._searchResults = changes.searchResults.currentValue;
    }
    if (changes.offerType) {
      this.resetFields();
    }
  }

  /**
   * Event listener for text input.
   * @param event
   */
  onTextChange(event) {
    this.resetFields();
    this.typed.emit(event.target.value);
  }

  /**
   * Reset the following fields
   */
  resetFields() {
    this.selectedSearchResults = [];
    this.displayNoMatches = false;
    this.shelfOpen = false;
  }

  /**
   * If you just wanna emit an event hand have it handled by parent component.
   * @param event
   * @param eventName
   */
  justEmitEvent(event, eventName) {
    this[eventName].emit(event);
  }

  /**
   * Public function to set search results. Search results appear underneath the text input.
   * @param results
   */
  setSearchResults(results) {
    this._searchResults = results;
    this.changeSearchResults.emit(results);
  }

  /**
   * Clears search text.
   */
  clearText() {
    this.enteredText = '';
  }

  /**
   * Open shelf when text input is focused.
   */
  onTextFocus() {
    this.displayNoMatches = false;
    this.textFocus.emit();
  }

  /**
   * Toggle search result index in selectedSearchResults array on click.
   * @param resultIndex Index of search result in _searchResults array.
   */
  onClickSearchResult(resultIndex) {
    if (this.selectedSearchResults.includes(resultIndex)) {
      // remove the single selection from the array (toggle off the checkmark)
      const indexInArray = this.selectedSearchResults.findIndex(item => item === resultIndex);
      this.selectedSearchResults.splice(indexInArray, 1);
    } else {
      // clear array if only allowing 1 selection at a time
      if (this.multiSelect === false) {
        this.selectedSearchResults = [];
      }
      this.selectedSearchResults.push(resultIndex);
    }
  }


  /**
   * Handler for clicking "Add Items" button. Adds search results to selected items.
   */
  onClickButton() {
    const items = this._selectedItems;
    this._searchResults.forEach((result, index) => {
      // only push if not already in this._selectedItems (to avoid adding duplicates)
      if (this.selectedSearchResults.includes(index) && !some(this._selectedItems, result)) {
        items.push(result);
      }
    }, this);
    this.resetFields();
    this.onSelectItems.emit(items);
    this.setSearchResults([]);
    this.clearText();
  }

  /**
   * Event handler for removing item button.
   * @param index
   */
  onRemoveItem(index) {
    this._selectedItems.splice(index, 1);
    this.onSelectItems.emit(this._selectedItems);
  }

  isButtonDisabled(): boolean {
    // disable if disabled prop OR no search results
    return this.disabled || !(this.selectedSearchResults && this.selectedSearchResults.length);
  }

}
