import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    AfterViewInit,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { Stop } from '../../../shipment/models/order.model';
import { OfferType } from '../../../builder/models/order.model';
import { OrderEditMode, AssignmentsDto } from '../../../core/models/order.dto';
import { DriverDto, PoolDto, PoolSelectDto } from '../../../core/models/dto';
import { SelectComponent } from '../../../core/components/pool-select';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { OrderSelectors } from '../../../shipment/selectors/order.selectors';
import { ShipmentEditSelectors } from '../../../shipment-edit/selectors/shipment-edit.selectors';

@Component({
  selector: 'tuya-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignDriverComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('exclusiveDriversSelect', {static: true}) exclusiveDriversSelect: SelectComponent;
  @ViewChild('driverPoolsSelect', {static: true}) driverPoolsSelect: SelectComponent;

  orderEditMode;

  dontDebounce = false;

  @select(ShipmentEditSelectors.selectEditMode)
  orderEditMode$: Observable<OrderEditMode>;
  @select(OrderSelectors.orderDeliveryTypeSelector)
  orderDeliveryType$: Observable<number>;
  @select(OrderSelectors.orderStopListSelector)
  orderStopList$: Observable<Stop[]>;
  @select(['admin', 'shipperAdmin', 'isReofferLoading'])
  isLoading$: Observable<boolean>;

  @Output() driverSearch = new EventEmitter<object>();
  @Output() setExtendable = new EventEmitter<boolean>();
  @Output() driverTypeOffer = new EventEmitter<any>();
  @Input() isExtendable = false;
  @Input() vehicleType;
  @Input() isDriversLoading;

  public disabledLimited: boolean;
  public driver = null;
  public disabledExclusive: boolean;
  public OfferType: typeof OfferType = OfferType;
  public OrderEditMode: typeof OrderEditMode = OrderEditMode;
  public $inputSubject: Subject<string> = new Subject();
  public MIN_LENGTH_STRING = 6;
  public disabledIsExtendable = false;

  private _inputSubscription: Subscription;
  private _pools: PoolDto[] = [];
  private _drivers: DriverDto[] = [];
  private driverIdsFromDriverList: Array<any> = [];
  private poolsIdsFromPoolsList: Array<any> = [];
  private _assignments: AssignmentsDto = <AssignmentsDto>{};

  get pools() {
    return this._pools;
  }

  get drivers() {
      return this._drivers;
  }

  private _offerType: OfferType = OfferType.Any;

  @Input() set offerType(offerType) {
    this._offerType = offerType;
    this.disableSearchFields(offerType);
  }

  get offerType() {
      return this._offerType;
  }

  @Input() set assignments(assignments) {
      if (!assignments) {
          assignments = { drivers: [], pools: [] };
      }
      this._assignments = assignments;
      this.addDataToSelects();
  }

  @Input() set drivers(drivers: DriverDto[]) {
    this._drivers = drivers;
  }

  @Input() set pools(pools: PoolDto[]) {
      this._pools = pools.filter(item => item.drivers.length);
  }

  constructor() {
    this.$inputSubject = new Subject<string>();
  
    this._inputSubscription = this.$inputSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(term => this.driverSearch.next({ term: term, vehicle: 0 }));
  }

  ngOnInit() {
    this.exclusiveDriversSelect.items = [];
  }

  ngOnChanges(changes: SimpleChanges) {
      if (changes.drivers && this.dontDebounce) {
          this._onSendInputData(changes.drivers.currentValue);
      }
      this.dontDebounce = true;
  }

  ngOnDestroy() {
      this._inputSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.addDataToSelects();
  }

  disableSearchFields(offerType: OfferType) {
    if (offerType === this.OfferType.Any) {
        this._setSelectsState(true);
    } else if (offerType === this.OfferType.DriversPool) {
        this._setDisabledExclusive(true);
        this._setDisabledLimited(false);
    } else if (offerType === this.OfferType.Exclusive) {
        this._setDisabledExclusive(false);
        this._setDisabledLimited(true);
    }

    this.setExtendableBehaviour(offerType === this.OfferType.Any);
  }

  public onTypedEventHandler(input: string) {
      if (input.length >= this.MIN_LENGTH_STRING) {
          this.$inputSubject.next(input);
      }
  }

  public isDisabledDriverSection() {
    return this.orderEditMode === OrderEditMode.PartiallyCompleted ||
        this.orderEditMode === OrderEditMode.Accepted;
  }

  public getDisabledAnyStatement(): boolean {
      return !(this.isDisabledDriverSection() && this.offerType !== OfferType.Any);
  }

  public getDisabledExclusiveStatement(): boolean {
      return !(this.isDisabledDriverSection() && this.offerType === OfferType.Any);
  }

  public clearSelectedDriversTags () {
      this.exclusiveDriversSelect.clearActiveTags();
      this.driverPoolsSelect.clearActiveTags();
  }

  public setTagsIntoSelect(component: SelectComponent, options: PoolSelectDto[] | DriverDto[]) {
      component.setSelectOptions(options);
      component.addTagsToSelect();
  }

  public submitSelectedPools(e) {
      this.poolsIdsFromPoolsList = this.getPoolsIdsArrayFromPools(e);
      if (this.offerType !== this.OfferType.Any ) {
          this._updateOrder(this._getAssignedData());
      }
  }

  public changeExtendable() {
      this.setExtendable.emit(this.isExtendable);
  }

  public driverTypeClick(event: OfferType) {
      this.driverTypeOffer.emit({ type: event, data: {} });
      this._offerType = event;

      if (event === this.OfferType.Any) {
        this.clearSelectedDriversTags();
      } else if (event === this.OfferType.DriversPool) {
        this.exclusiveDriversSelect.clearActiveTags();
      } else if (event === this.OfferType.Exclusive) {
        this.driverPoolsSelect.clearActiveTags();
      }
  }

  public submitSelectedDrivers(e) {
      this.driverIdsFromDriverList = this.getUniqDriversidsArrayFromDrivers(e);
      if (this.offerType !== this.OfferType.Any ) {
          this._updateOrder(this._getAssignedData());
      }

  }

  private _getAssignedData() {
      return {
          type: this.offerType,
          data: {
              driverUserAccountIds: this.driverIdsFromDriverList,
              driverPoolIds: this.poolsIdsFromPoolsList
          }
      };

  }

  private getPoolsIdsArrayFromPools(selectedItems) {
      return selectedItems.map(item => {
        return {
          id: item.id,
          name: item.name
        };
      });
  }

  private _updateOrder(assignData: Object | null) {
      this.driverTypeOffer.emit(assignData);
  }

  private addDataToSelects() {
      if (this._assignments.drivers && this._assignments.drivers.length) {
          this.setTagsIntoSelect(this.exclusiveDriversSelect, this._assignments.drivers);
      }
      if (this._assignments.pools && this._assignments.pools.length) {
          this.setTagsIntoSelect(this.driverPoolsSelect, this._assignments.pools);
      }
  }

  private _setSelectsState(state: boolean) {
      this._setDisabledExclusive(state);
      this._setDisabledLimited(state);
  }

  private _setDisabledExclusive(state: boolean) {
    this.disabledExclusive = state;
  }

  private _setDisabledLimited(state: boolean) {
    this.disabledLimited = state;
  }

  private _onSendInputData(drivers) {
      if (drivers.length) {
          this.exclusiveDriversSelect.items = drivers;
          this.exclusiveDriversSelect.openSelect();
      } else {
          this.exclusiveDriversSelect.items = [];
      }
  }

  private setExtendableBehaviour(assignmentType: boolean) {
      this.disabledIsExtendable = assignmentType;
      this.isExtendable = !assignmentType;
      this.changeExtendable();
  }

  private getUniqDriversidsArrayFromDrivers(selectedItems) {
    return selectedItems.reduce((p, c) => {
        if (p.indexOf(c.id) === -1) {
            p.push(c.id);
        }
        return p;
    }, []);
  }
}
